<template>
    <section class="info-container">
        <section class="info-item-wrap">
            <p class="info-title">里程碑节点</p>
            <a-row class="time-line-container" >
                <a class="prev-nav-icon" >
                    <a-icon type="caret-left" @click="prev" />
                </a>
                <a class="next-nav-icon" >
                    <a-icon type="caret-right" @click="next" />
                </a>
                <a-row class="time-line"></a-row>
                <a-col class="item-wrap" style="height:156px" v-for="item in pointList" :key="item.id" v-show="item.key> min && item.key < max">
                    <a-row v-if="item.id">
                        <a class="img-wrap">
                            <img @click="selectPoint(item)" :src="item.codeUrl" alt="">
                            <a class="action-icon">
                                <a @click="showPointModal(item,1,item.key)" title="编辑"><a-icon type="edit" class="m-r-10"></a-icon></a>
                                <a-popconfirm title="确定删除该节点?" @confirm="deletePoint(item.id)"><a title="删除"><a-icon style="color:red" type="delete"></a-icon></a></a-popconfirm>
                            </a>
                        </a>
                        <div class="wrap-point">
                            <span :class="pointId == item.id ? 'active' : ''"></span>
                        </div>
                        <a class="add-wrap-left" @click="showPointModal(item,null,item.key)">
                            <a-icon  type="plus-circle" theme="filled" />
                        </a>
                        <a v-if="pointList.length == item.key" class="add-wrap-right" @click="showPointModal(item,null,(item.key+1))">
                            <a-icon type="plus-circle" theme="filled" />
                        </a>
                        <a-row class="wrap-text">   
                            <p>{{item.name}}</p>
                            <p>
                                <!-- <span class="key">计划时间</span> -->
                                <span >{{item.startDate}}~{{item.endDate}}</span>
                            </p>
                        </a-row>
                    </a-row>
                    <a-row v-show="!item.id">
                        <a class="add-wrap-left" @click="showPointModal(item,null,item.key)">
                            <a-icon type="plus-circle" theme="filled" />
                        </a>
                    </a-row>
                </a-col>
            </a-row>
        </section>

       <section class="info-item-wrap m-t-18">
           <p class="info-title ">
               <span>配置子里程碑</span>
               <a v-if="pointId" class="action-btn" @click="showChildMilestoneModal(null)"><a-icon type="plus" /></a>
           </p>
            <section class="list-table-container m-t-18">
                <a-table size="small" :columns="columns" :data-source="listMap" :pagination="{total,hideOnSinglePage:true}">
                    <a slot="action" slot-scope="text,list">
                        <a class="m-r-10" @click="showChildMilestoneModal(list)">编辑</a>
                        <a-popconfirm title="确定删除该子里程碑?" @confirm="deleteItem(list.id)">删除</a-popconfirm>
                    </a>
                </a-table>
            </section>
       </section>

       <!-- modal -->
       <a-modal centered width="660px" class="modal-container" :title="pointModal.title" v-model="pointModal.visible" :footer="false" :destroyOnClose="true">
            
           <a-form :form="form">
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="节点名称">
                   <a-input placeholder="请输入" v-decorator="['name',{rules:[{required:true,message:'子里程碑不能为空'}]}]" ></a-input>
               </a-form-item>
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="计划开始">
                   <a-date-picker :disabledDate="disabledStartDate" placeholder="请选择" v-decorator="['startDate',{rules:[{required:true,message:'计划开始不能为空'}]}]"  />
               </a-form-item>
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="计划结束">
                   <a-date-picker :disabledDate="disabledEndDate" v-decorator="['endDate',{rules:[{required:true,message:'计划结束不能为空'}]}]" />
               </a-form-item>
                <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="上传照片">
                   <a-row type="flex">
                       <a-row class="upload-preview-wrap">
                           <a v-if="files.codeUrl" class="file-item m-r-10">
                               <a-avatar  shape="square" :size="100" :src="picServer+files.codeUrl" />
                           </a>
                       </a-row>
                       <upload style="display:inline-block;width:100px" v-decorator="['nodeUrl',{rules:[{required:false,message:'请上传照片'}]}]" :node="{type:3,text:'上传'}" :accept="['.png','.jpg']" @func="getFile" />
                   </a-row>
                   
               </a-form-item>
               <a-row class="form-btn-group">
                   <a-button class="btn-save" @click="pointSubmit"><a-icon type="save"  />保存</a-button>
                   <a-button class="btn-cancel"  @click="pointModal.visible = false">取消</a-button>
               </a-row>
           </a-form>
        </a-modal>


        <a-modal centered width="660px" class="modal-container" :title="childMilestoneModal.title" v-model="childMilestoneModal.visible" :footer="false" :destroyOnClose="true">
            
           <a-form :form="childMilestoneForm">
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="子里程碑">
                   <a-input placeholder="请输入" v-decorator="['sonName',{rules:[{required:true,message:'子里程碑不能为空'}]}]" ></a-input>
               </a-form-item>
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="计划开始">
                   <a-date-picker :disabledDate="disablePlanStartDate" placeholder="请选择" v-decorator="['planStartDate',{rules:[{required:true,message:'计划开始不能为空'}]}]"  />
               </a-form-item>
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="计划结束">
                   <a-date-picker :disabledDate="disablePlanEndDate" v-decorator="['planEndDate',{rules:[{required:true,message:'计划结束不能为空'}]}]" />
               </a-form-item>
               <a-row class="form-btn-group">
                   <a-button class="btn-save" @click="childMilestoneSubmit"><a-icon type="save"  />保存</a-button>
                   <a-button class="btn-cancel"  @click="childMilestoneModal.visible = false">取消</a-button>
               </a-row>
           </a-form>
        </a-modal>
    </section>
</template>

<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key'},
    {title:'子里程碑',dataIndex:'sonName',align:'center',key:'sonName'},
    {title:'计划开始',dataIndex:'planStartDate',align:'center',key:'planStartDate'},
    {title:'计划完成',dataIndex:'planEndDate',align:'center',key:'planEndDate'},
    {title:'工期(天)',dataIndex:'planDay',align:'center',key:'actualDay'},
    {title:'操作',scopedSlots:{customRender: "action"},align:'center'},
]
import moment from 'moment'
import upload from '@/components/upload'
export default {
    data(){
        return{
            total:0,
            columns,
            pointModal:{
                visible:false,
                title:'添加里程碑节点'
            },
            childMilestoneModal:{
                visible:false,
                title:'添加子里程碑'
            },
            listMap:[], //子里程碑列表
            childMilestoneId:'',  //子里程碑id
            pointList:[],   //节点列表
            pointId:'', //节点id
            
            pointActiveKey:1,
            files:{},
            sort:'',
            actionType:0,
            min:0,
            max:6,
            planDay:0,
            currentPoint:{}
        }
    },
    components:{upload},
    computed:{
        milestoneId(){  //里程碑id
            return this.$route.query.id
        },
        picServer(){
            return this.$store.state.picServer
        }
    },
    beforeCreate(){
        this.form = this.$form.createForm(this)
        this.childMilestoneForm = this.$form.createForm(this)
    },
    created() {
        this.queryMilestoneConfigList()
        //this.queryMilestoneSonList()
    },
    methods:{
        //计划开始时间限制
        disabledStartDate(startDate){
            const endDate = this.form.getFieldValue("endDate");
            let index = this.sort
            let preStartDate = (index - 2) < 0 ? null :this.pointList.map(item => item.startDate)[index - 2]
            let nextStartDate = this.pointList.map(item => item.startDate)[index - 1]
            if (!startDate) return false;
            return (preStartDate && startDate.valueOf() < moment(preStartDate).valueOf()  ) || (nextStartDate && startDate.valueOf() >  moment(nextStartDate).valueOf() ) || (endDate && startDate.valueOf() > endDate.valueOf());
        },
        //计划结束时间限制
        disabledEndDate(endValue) {
            let index = this.sort
            let preEndDate = this.pointList.map(item => item.endDate)[index - 2]
            let nextEndDate = this.pointList.map(item => item.endDate)[index - (this.actionType == 1 ? 0 : 1)]
             const startDate = this.form.getFieldValue("startDate");
            if (!endValue) return false;
            return (preEndDate && endValue.valueOf() < moment(preEndDate).valueOf()) || (nextEndDate && endValue.valueOf() >  moment(nextEndDate).valueOf()) || (startDate && endValue.valueOf() < startDate.valueOf());
        },
        //查询里程碑节点
        queryMilestoneConfigList(){
            this.$api.queryMilestoneConfigList({milestoneId:this.milestoneId}).then(res =>{
                if(res.code === 200){
                    let _data = res.data || []
                    let pointList =  _data.length ?  _data : [{key:0}]
                    
                    pointList.forEach((item,ind) =>{
                        item.key = ind+1
                        item.codeUrl = item.nodeUrl ? this.picServer+item.nodeUrl : require('../../../../assets/image/icon/default-image.png')
                        //item.nodeUrl = item.nodeUrl ? this.picServer+item.nodeUrl : require('../../../../assets/image/icon/default-image.png')
                    })
                    this.pointId = this.pointId ? this.pointId : (pointList.length ? pointList[0].id : '')
                    this.currentPoint = pointList.length ? pointList[0] : {}
                    this.pointList = pointList
                    this.queryMilestoneSonList()
                }
            })
        },
        prev(){
            this.min = this.min > 0 ? this.min - 1 : 0
            this.max = this.min == 0 ? 6 : this.max -1
        },
        next(){
            let  l = this.pointList.length+1
            if(l < 6) return false
            this.min = this.max == l ? l - 6 : this.min + 1
            this.max = this.max <= l ? this.min + 6 : l
        },
        showPointModal(list,actionType,sort){
            this.pointModal.visible = true
            this.pointModal.title = actionType == 1 ? '编辑节点' : '新增节点'
            this.actionType = actionType
            this.currentPoint = list
           
            this.pointId = list.id ? list.id : ''
            this.sort = sort
            this.files = {}
          
            if(actionType == 1){
                let formData = {
                    name:list.name,
                    startDate:list.startDate ? moment(list.startDate) : null,
                    endDate:list.endDate ? moment(list.endDate) : null,
                    nodeUrl:list.nodeUrl
                }
                if(list.nodeUrl){
                    formData.nodeUrl =list.nodeUrl
                    this.files = {
                        codeUrl:list.nodeUrl,
                    }
                    
                }
                this.$nextTick(() =>{
                    this.form.setFieldsValue({
                        name:list.name,
                        startDate:list.startDate ? moment(list.startDate) : null,
                        endDate:list.endDate ? moment(list.endDate) : null,
                        nodeUrl:list.nodeUrl
                    })
                })
            }
           
        },
        getFile(file){
            this.files = file
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    nodeUrl:file.codeUrl
                })
            })
        },
        //选择某个节点，查询配置子里程碑
        selectPoint(list){
            this.pointId = list.id
            this.currentPoint = list
            //console.log(this.currentPoint)
            //this.listMap = []
            this.queryMilestoneSonList()
        },
        //新增和编辑里程碑节点
        pointSubmit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    formData.startDate = formData.startDate.format('YYYY-MM-DD')  
                    formData.endDate = formData.endDate.format('YYYY-MM-DD')
                    formData.sort = this.sort
                    formData.milestoneId = this.milestoneId
                    if(this.actionType == 1){
                        formData.id = this.pointId
                    }
                    let apiName = this.actionType == 1 ? 'updateMilestoneConfig' : 'insertMilestoneConfig'
                    this.$api[apiName](formData).then(res =>{
                        if(res.code === 200){
                            this.queryMilestoneConfigList()
                            this.pointModal.visible = false
                        }
                    })
                }
            })
        },
        //删除里程碑节点
        deletePoint(id){
            this.$api.deleteMilestoneConfig(id).then(res =>{
                if(res.code === 200){
                    this.queryMilestoneConfigList()
                }
            })
        },

        
        //子里程碑列表
        queryMilestoneSonList(){
            if(!this.pointId) return false
            this.$api.queryMilestoneSonList({configId:this.pointId}).then(res =>{
                if(res.code === 200){
                    this.listMap = res.data || []
                    this.listMap.forEach((item,ind) =>{
                        item.key = ind +1
                    })
                }
            })
        },
        disablePlanStartDate(sDate){
            const eDate = this.childMilestoneForm.getFieldValue("planEndDate");
            let point =  this.currentPoint
            if (!sDate) return false;
            return sDate.valueOf() < moment(point.startDate).valueOf() || (eDate && sDate.valueOf() > eDate.valueOf()) ||  sDate.valueOf() > moment(point.endDate).valueOf()
        },
        disablePlanEndDate(eDate){
            const sDate = this.childMilestoneForm.getFieldValue("planStartDate");
            let point =  this.currentPoint
            if (!eDate) return false;
            return eDate.valueOf() > moment(point.endDate).valueOf() || (sDate && eDate.valueOf() < sDate.valueOf()) ||  eDate.valueOf() < moment(point.startDate).valueOf()
        },
        //子里程碑新增编辑modal
        showChildMilestoneModal(list){
            this.childMilestoneModal.visible = true
            this.childMilestoneId = list ? list.id : ''
            this.childMilestoneModal.title = this.childMilestoneId ? '编辑子里程碑' : '新增子里程碑'

            if(this.childMilestoneId){
                this.$nextTick(() =>{
                    this.childMilestoneForm.setFieldsValue({
                        sonName:list.sonName,
                        planStartDate:list.planStartDate ? moment(list.planStartDate) : null,
                        planEndDate:list.planEndDate ? moment(list.planEndDate) : null,
                    })
                })
            }
        },
        //子里程碑新增编辑提交
        childMilestoneSubmit(){
            this.childMilestoneForm.validateFields((err,formData) =>{
                if(!err){
                    formData.configId = this.pointId
                    
                    formData.planStartDate = formData.planStartDate ? formData.planStartDate.format('YYYY-MM-DD') : ''
                    formData.planEndDate = formData.planEndDate ? formData.planEndDate.format('YYYY-MM-DD') : ''
                    formData.planDay = moment(formData.planEndDate).diff(formData.planStartDate,'day')
                    
                    if(this.childMilestoneId){
                        formData.id = this.childMilestoneId
                    }
                    let apiName = this.childMilestoneId ? 'updateMilestoneSon' : 'insertMilestoneSon'
                    this.$api[apiName](formData).then(res =>{
                        if(res.code === 200){
                            this.queryMilestoneSonList()
                            this.childMilestoneModal.visible = false
                        }
                    })
                }
            })
        },
        //删除子里程碑
        deleteItem(id){
            this.$api.deleteMilestoneSon(id).then(res =>{
                if(res.code === 200){
                    this.queryMilestoneSonList()
                }
            })
        }
    }
}
</script>